<footer class="footer">
  <div class="container">
    <app-footer-nav class="footer-nav"></app-footer-nav>
    <hr />
    <div class="copy-right">
      <p>
        <span>Copyright © {{ currentYear }}</span> <span>Count Me In.</span><span> All rights reserved.</span>
      </p>
      <ul>
        <li>Contact Us</li>
        <li><a href="mailto:info@lmsproject.org">info@lmsproject.org</a></li>
        <li><a href="tel:651-403-5556">651-403-5556</a></li>
      </ul>
    </div>
  </div>
</footer>
