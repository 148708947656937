import {Component} from '@angular/core';

@Component({
  selector: 'app-for-your-physician',
  templateUrl: './for-your-physician.component.html',
  styleUrls: ['./for-your-physician.component.scss'],
})
export class ForYourPhysicianComponent {

  FYP = 'https://storage.googleapis.com/cmi-study-dev-assets/lms/pdf/48.%20Information%20for%20physicians%20PDF%20LMS%20Clean%2022Dec%202022.pdf';


}
