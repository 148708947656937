export enum Route {
  Home = '',
  About = 'about',
  FAQ = 'faq',
  ForYourPhysician = 'for-your-physician',
  HowToParticipate = 'how-to-participate',
  ScientificImpact = 'scientific-impact',
  CountMeIn = 'count-me-in',
  Activity = 'activity',
  ActivityId = 'activity/:id',
  ActivityLinkId = 'activity-link/:id',
  AgeUpAccept = 'accept',
  AgeUpVerify = 'verify',
  AgeUpThankYouProxy = 'proxy-thank-you',
  AgeUpThankYouVerify = 'thank-you',
  Dashboard = 'dashboard',
  Error = 'error',
  LoginLanding = 'login-landing',
  LoginLandingMode = 'login-landing/:mode',
  Auth = 'auth',
  Password = 'password',
  PasswordResetDone = 'password-reset-done',
  SessionExpired = 'session-expired',
}
