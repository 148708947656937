<div class="mobile-nav-container">
  <ul class="links-list">
    <li>
      <ng-content select="[backToTop]"></ng-content>
    </li>

    <li class="links-list__item">
      <a class="link" queryParamsHandling="merge" routerLinkActive="link--active" [routerLink]="Route.About">
        {{ 'Header.Links.About' | translate }}
      </a>
    </li>

    <li class="links-list__item">
      <a class="link" queryParamsHandling="merge" routerLinkActive="link--active" [routerLink]="Route.HowToParticipate">
        {{ 'Header.Links.Participation' | translate }}
      </a>
    </li>

    <li class="links-list__item">
      <a class="link" queryParamsHandling="merge" routerLinkActive="link--active" [routerLink]="Route.ScientificImpact">
        {{ 'Header.Links.ScientificImpact' | translate }}
      </a>
    </li>

    <li class="links-list__item">
      <a class="link" queryParamsHandling="merge" routerLinkActive="link--active" [routerLink]="Route.FAQ">
        {{ 'Header.Links.FAQ' | translate }}
      </a>
    </li>

    <li class="links-list__item">
      <a type="button" class="link" (click)="openJoinMailingList()">
        {{ 'Header.Links.MailingList' | translate }}
      </a>
    </li>

    <li class="links-list__item">
      <a class="link" queryParamsHandling="merge" routerLinkActive="link--active" [routerLink]="Route.ForYourPhysician">
        {{ 'Header.Links.ForYourPhysician' | translate }}
      </a>
    </li>
  </ul>

  <div class="buttons" *ngIf="isAuthenticated">
    <div
      *ngIf="headerConfig.showLoginButton"
      class="auth__login header-link"
      [ngClass]="{ 'header-button': !headerConfig.showMainButtons }"
    >
      <ddp-sign-in-out></ddp-sign-in-out>
    </div>
    <div class="auth__dashboard">
      <button class="header-link simple-button cmi-button" routerLink="dashboard" routerLinkActive="header-link_active">
        <div></div>
        <span translate>Common.Navigation.Dashboard</span>
      </button>
    </div>
  </div>

  <div class="buttons" *ngIf="!isAuthenticated && headerConfig.showLoginButton">
    <ddp-sign-in-out></ddp-sign-in-out>
    <a class="button button_primary cmi-button" *ngIf="!isAuthenticated" [routerLink]="Route.CountMeIn">
      {{ 'Header.Links.CountMeIn' | translate }}
    </a>
  </div>
</div>
