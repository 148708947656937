<div class="main">
  <div class="headerPlace">
    <div class="headerText">
      {{ 'FYP.FYP.HeaderText' | translate }}
    </div>
  </div>
  <div class="purposePlace">
    <div class="inner-container">
      <div class="the-purpose-of-this">
        {{ 'FYP.FYP.Purpose' | translate }}
      </div>
      <a class="btn" [href]="FYP" target="_blank">
        <button class="downloadPdf">
        <span class="downloadTitle">
          {{ 'FYP.FYP.downloadTitle' | translate }}
        </span>
        </button>
      </a>
    </div>
  </div>
  <div class="paragraphsList">
    <div class="paragraph">
      <span class="paragraphTitle">{{ 'FYP.FYP.paragraphTitle1' | translate }}
        <span class="paragraphText">{{ 'FYP.FYP.paragraphTextPart1' | translate }}</span>
        <span class="paragraphText1Highlighted" [innerHTML]="'FYP.FYP.paragraphTextPart2' | translate"></span>
        <span class="paragraphText">{{ 'FYP.FYP.paragraphTextPart3' | translate }}</span>
      </span>
    </div>
    <div class="paragraph">
      <div class="paragraphTitle">{{ 'FYP.FYP.paragraphTitle2' | translate }}</div>
      <div class="paragraphText partOne">{{ 'FYP.FYP.paragraphText2' | translate }}</div>
    </div>
    <div class="paragraph">
      <div class="paragraphTitle">{{ 'FYP.FYP.paragraphTitle3' | translate }}</div>
      <div class="paragraphText partOne">{{ 'FYP.FYP.paragraphText3' | translate }}</div>
    </div>
    <div class="paragraph">
      <div class="paragraphTitle">{{ 'FYP.FYP.paragraphTitle4' | translate }}</div>
      <div class="paragraphText partOne">{{ 'FYP.FYP.paragraphText4' | translate }}</div>
    </div>
    <div class="paragraph">
      <div class="paragraphTitle">{{ 'FYP.FYP.paragraphTitle5' | translate }}</div>
      <div class="paragraphText partOne">{{ 'FYP.FYP.paragraphText5' | translate }}</div>
      <div class="paragraphText">{{ 'FYP.FYP.paragraphText5part2' | translate }}</div>
    </div>
    <div class="paragraph">
      <div class="paragraphTitle">{{ 'FYP.FYP.paragraphTitle6' | translate }}</div>
      <div class="paragraphText">{{ 'FYP.FYP.paragraphText6' | translate }}</div>
    </div>
    <div class="paragraph">
      <div class="paragraphTitle">{{ 'FYP.FYP.paragraphTitle7' | translate }}</div>
      <div class="paragraphText">{{ 'FYP.FYP.paragraphText7' | translate }}</div>
    </div>
    <div class="paragraph">
      <div class="paragraphTitle">{{ 'FYP.FYP.paragraphTitle8' | translate }}</div>
      <div class="paragraphText partOne">{{ 'FYP.FYP.paragraphText8' | translate }}</div>
      <div class="paragraphText partOne">{{ 'FYP.FYP.paragraphText8part2' | translate }}</div>
      <div class="paragraphText partOne">
        <ul>
          <li>{{ 'FYP.FYP.paragraphText8list1' | translate }}</li>
          <li>{{ 'FYP.FYP.paragraphText8list2' | translate }}</li>
          <li>{{ 'FYP.FYP.paragraphText8list3' | translate }}</li>
          <li>{{ 'FYP.FYP.paragraphText8list4' | translate }}</li>
        </ul>
      </div>
      <div class="paragraphText partOne">{{ 'FYP.FYP.paragraphText8part3' | translate }}</div>
      <div class="paragraphText partOne">{{ 'FYP.FYP.paragraphText8part4' | translate }}
        <div [innerHTML]="'FYP.FYP.paragraphText8part5' | translate"></div>
      </div>
      <div class="paragraphText">{{ 'FYP.FYP.paragraphText8part6' | translate }}</div>
    </div>
  </div>
</div>
