<main class="participation">
  <header>
    <h2 class="static-page-title" translate>About.Title</h2>

    <div class="count-me-in">
      <img class="img" src="/assets/images/logo-count-me-in.png" alt="count me in" />
      <p class="text" [innerHTML]="'About.TitleText' | translate"></p>

      <button class="cmi-visit-button" onclick="window.open('https://joincountmein.org/', '_blank')">
        <span class="cmi-visit-button-text">{{ 'About.button1' | translate }}</span>
        <mat-icon>launch</mat-icon>
      </button>

      <p class="text" [innerHTML]="'About.countMeInText' | translate"></p>

    </div>
  </header>

  <section class="advisory">
    <div class="content">
      <div class="headline">
        <p>{{ 'About.AdvisorsBlock.Headline' | translate }}</p>
      </div>

      <div class="summary">
        <p [innerHTML]="'About.AdvisorsBlock.ProjectSummary' | translate"></p>
      </div>

      <div class="advisors-info" *ngFor="let advisor of 'About.AdvisorsBlock.Advisors' | translate">
        <img class="image" [src]="advisor.image" [alt]="advisor.name" />
        <div class="about">
          <h1 class="name">{{ advisor.name }}</h1>
          <p class="bio" [innerHTML]="advisor.bio"></p>
        </div>
      </div>
    </div>
  </section>

  <section class="champions">
    <h1 class="headline">{{ 'About.ChampionsBlock.Title' | translate }}</h1>

    <div class="list">
      <div class="item" *ngFor="let champion of 'About.ChampionsBlock.Champions' | translate">
        <img [src]="champion.image" />
        <aside>
          <h2>{{ champion.name }}</h2>
          <p [innerHTML]="champion.bio"></p>
        </aside>
      </div>
    </div>
  </section>

  <section class="learn-more-blank">
    <p class="headline">{{ 'About.LearnMoreHeadline' | translate }}</p>
    <button class="learn-more-button" [routerLink]="['/faq']">
      <p class="visit-FAQ">{{ 'About.VisitFaq' | translate }}</p>
    </button>
  </section>
</main>
